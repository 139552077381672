import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import { Box, Flex, Heading, Image, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import I18n from '../components/I18n';
import Layout from '../components/layout';
import SEO from '../components/seo';

import bloctoLogo from '../../static/images/icon-blocto-logo.svg';
import appStore from '../../static/images/img-app-store.png';
import playStore from '../../static/images/img-play-store.png';
// import bloctoDemoVideo from '../../static/videos/video-blocto-demo.mp4';
import demoPhone from '../../static/new_images/demo-phone.png';
import gtagEvent from '../utils/gtag';

const BackupLinks = {
  'iOS TestFlight': { link: 'https://testflight.apple.com/join/zxlMasyx', id: 'ios test flight' },
  'Android APK': { link: 'https://cdn.blocto.app/blocto-latest.apk', id: 'android apk' },
};

const aosAttributes = {
  'data-aos': 'fade-up',
  'data-aos-duration': '600',
};

const AppDemo = ({ ...rest }) => (
  <Box
    flex={{ base: '0 0 309px', lg: '0 0 432px' }}
    maxWidth={{ base: '309px', lg: '432px' }}
    marginTop={{ base: '40px', md: '0' }}
    position="relative"
    {...rest}
  >
    <Image src={demoPhone} width="100%" />

    {/* <Flex
        justifyContent="center"
        alignItems="center"
        width="calc(100% - 30px)"
        minWidth="220px"
        height="calc(100% - 2%)"
        maxHeight="609px"
        borderRadius="30px"
        position="absolute"
        top="11px"
        left="50%"
        zIndex="-1"
        transform="translateX(-50%)"
        bg="white"
        opacity={!isLoaded || !isAnimated ? 1 : 0}
        boxShadow="0px 20px 30px rgba(0, 0, 0, 0.1)"
        transition=".5s opacity"
      >
        <Image
          src={bloctoLogo}
          width="107px"
          height="107px"
          animation={expandAnimation}
          onAnimationEnd={handleAnimationEnd}
        />
      </Flex>

      <Box
        as="video"
        playsInline
        autoPlay
        muted
        loop
        width="calc(100% - 30px)"
        borderRadius="30px"
        position="absolute"
        top="11px"
        left="50%"
        zIndex="-2"
        transform="translateX(-50%)"
        boxShadow="0px 20px 30px rgba(0, 0, 0, 0.1)"
        sx={{
          '@media screen and (max-width: 432px)': {
            width: 'calc(100% - 24.5px)',
          },
        }}
        onLoadedData={handleLoadedData}
      >
        <source src={bloctoDemoVideo} type="video/mp4" />
      </Box> */}
  </Box>
);

const Download = ({ intl }) => {
  const isMinWidthLg = useBreakpointValue({ base: false, md: true });

  if (isMinWidthLg === undefined) {
    return (
      <SEO
        title="Download Blocto Crypto Wallet | App Download | Blocto"
        description="Dive into a seamless Web3 journey with Blocto. Smooth onboarding and a user-centric crypto wallet design. Sign up in just 30 secs for a brand new experience"
        path="download"
      />
    );
  }

  return (
    <Layout needBaseLayout>
      <SEO
        title={intl.formatMessage({ id: 'blocto.download.meta.title' })}
        description={intl.formatMessage({
          id: 'blocto.download.meta.description',
        })}
        path="download"
      />

      <Flex
        justifyContent={{ base: 'center', md: 'space-between' }}
        align="center"
        margin={{ base: '126px 0 200px', md: '150px 0 200px' }}
        position="relative"
      >
        <Flex
          flexDirection="column"
          alignItems={{ base: 'center', md: 'unset' }}
          maxWidth="580px"
          paddingTop={{ base: '0', md: '54px' }}
          marginRight={{ base: '0', md: 'min(16.5%, 198px)' }}
          textAlign={{ base: 'center', md: 'left' }}
          sx={{
            '@media screen and (min-width: 767px) and (max-width: 960px)': {
              marginRight: '20px',
            },
          }}
          {...aosAttributes}
        >
          <Box {...aosAttributes}>
            <Image
              src={bloctoLogo}
              alt="Blocto logo"
              display="inline-block"
              width={{ base: '100px', md: '120px' }}
              height={{ base: '100px', md: '120px' }}
              padding={{ base: '13px', md: '23px' }}
              borderRadius="24px"
              boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
            />
            <Heading
              as="h1"
              variant="h1"
              margin="30px auto"
              maxWidth={{ base: '304px', md: 'unset' }}
              whiteSpace="break-spaces"
            >
              <I18n id="blocto.download.title" />
            </Heading>

            <Text fontSize="20px" lineHeight="26px" whiteSpace="break-spaces">
              <I18n id="blocto.download.content" />
            </Text>
          </Box>

          <AppDemo
            display={{ base: 'unset', md: 'none' }}
            margin="60px 0"
            {...(isMinWidthLg
              ? { ...aosAttributes }
              : { ...aosAttributes, 'data-aos-delay': '1400' })}
          />

          <Box
            display={{ base: 'block', md: 'flex' }}
            justifyContent="space-between"
            maxWidth="384px"
            margin={{ base: '0 0 30px', md: '70px 0 30px' }}
            {...aosAttributes}
          >
            <Link
              href="https://apps.apple.com/app/blocto/id1481181682?utm_source=blocto&utm_medium=official+website"
              isExternal
              onClick={() => {
                gtagEvent('bloctoweb_download_app', { download_app_icon: 'apple store download' });
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                width="180px"
                height="60px"
                padding="12px"
                bg="#4D5472"
                borderRadius="12px"
                marginRight={{ base: '0', md: '24px' }}
                marginBottom={{ base: '20px', md: '0' }}
              >
                <Image src={appStore} width="100%" />
              </Flex>
            </Link>

            <Link
              href="https://play.google.com/store/apps/details?id=com.portto.blocto&utm_source=blocto&utm_medium=official+website"
              isExternal
              onClick={() => {
                gtagEvent('bloctoweb_download_app', { download_app_icon: 'google play download' });
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                width="180px"
                height="60px"
                padding="12px"
                bg="#4D5472"
                borderRadius="12px"
              >
                <Image src={playStore} width="100%" />
              </Flex>
            </Link>
          </Box>

          <Text
            fontSize="16px"
            lineHeight="22px"
            color="#7F7F7F"
            sx={{
              ' a': {
                display: 'inline-block',
                fontWeight: '500',
                textDecoration: 'underline',
              },
              ' a:hover': {
                textDecoration: 'underline',
              },
            }}
            {...aosAttributes}
          >
            <I18n
              id="blocto.download.backup"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components
                a: (str) => (
                  <Link
                    href={BackupLinks[str].link}
                    isExternal
                    onClick={() => {
                      gtagEvent('bloctoweb_download_app', {
                        download_app_icon: BackupLinks[str].id,
                      });
                    }}
                  >
                    {str}
                  </Link>
                ),
              }}
            />
          </Text>
        </Flex>

        <AppDemo
          display={{ base: 'none', md: 'unset' }}
          sx={{
            '@media screen and (min-width: 767px) and (max-width: 960px)': {
              '&[data-aos][data-aos].aos-animate': {
                transform: 'scale(0.9)',
              },
            },
          }}
          {...aosAttributes}
        />
      </Flex>
    </Layout>
  );
};

Download.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};
export default injectIntl(Download);
